.guests {
  width: 100%;
  padding: 30px 220px;
  background: linear-gradient(180deg, #b5cbd8 0%, #fcfefd 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (width < 1920px) {
    padding: 30px 10vw;
  }

  &-title {
    font-family: "Advokat";
    font-size: 80px;
    font-weight: 400;
    line-height: 87.21px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #3b5b70;
  }

  &-slider {
    margin-top: 50px;
    flex: 1;
  }

  .slick-arrow {
    &:before {
      color: #3b5b70;
      font-size: 40px;
    }

    &.slick-prev {
      left: -50px;
    }
  }
}

.guest-card {
  max-width: 350px;
  padding: 10px;
  margin: auto;
}

.guest-photo {
}

.guest-name {
  font-family: "OpenSans";
  font-size: 32px;
  font-weight: 700;
  line-height: 34.88px;
  letter-spacing: 0.05em;
  text-align: left;
  margin-top: 15px;
  text-transform: uppercase;
}

.guest-bio {
  font-family: "OpenSans";
  font-size: 16px;
  font-weight: 300;
  line-height: 17.44px;
  letter-spacing: 0.05em;
  text-align: left;

  margin-top: 10px;
}

.mobile .guests {
  padding: 30px 5vw;

  &-title {
    font-size: 36px;
    line-height: 39.24px;
  }

  &-slider {
    flex: 1;
    width: 85%;
    margin: auto;
    margin-top: 10px;
  }

  .guest-name {
    font-size: 24px;
    line-height: 26.16px;
  }

  .guest-bio {
    font-size: 14px;
    line-height: 15.26px;
  }

  .slick-arrow {
    &.slick-prev {
      left: -40px;
    }
  }
}
