.partners {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 220px;
  background: linear-gradient(180deg, #b5cbd8 0%, #fcfefd 100%);

  @media (width < 1920px) {
    padding: 30px 10vw;
  }

  &-title {
    font-family: "Advokat";
    font-size: 80px;
    font-weight: 400;
    line-height: 87.21px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #3b5b70;
  }

  &-partner {
    cursor: pointer;
  }

  &-grid {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
  }
}

.mobile {
  .partners {
    padding: 30px 5vw;

    &-title {
      font-size: 36px;
      line-height: 39.24px;
    }

    &-grid {
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-column-gap: unset;
      grid-row-gap: unset;
    }
  }
}
