.header {
  background-color: rgb(168, 209, 216);
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 1019px;

  display: flex;
  flex-direction: column;

  &-main-block {
    display: flex;
    flex-direction: row;
    margin-top: 120px;
    justify-content: space-between;

    &_left-side {
      padding-left: 150px;

      @media (width < 1920px) {
        padding-left: 1.5vw;
      }
    }

    &_right-side {
      padding-right: 50px;

      @media (width < 1920px) {
        padding-right: 1vw;
      }
    }

    &_logo {
      width: 450px;
      min-width: 150px;
      height: auto;
    }
  }

  &-title-block {
    display: flex;
    flex-direction: column;
    text-align: right;

    &_t1 {
      font-family: "Advokat";
      font-weight: 400;
      font-size: 96px;
      line-height: 104px;
      letter-spacing: 10px;
      color: #7d9597;

      @media (width < 1920px) {
        font-size: 3rem;
      }
    }

    &_t2 {
      font-family: "Advokat";
      font-weight: 400;
      font-size: 200px;
      line-height: 180px;
      letter-spacing: 30px;
      color: #fff;
      text-align: right;

      @media (width < 1920px) {
        font-size: 8rem;
        line-height: 7rem;
      }

      &__wide {
        letter-spacing: 52px;
        transform: translateX(25px);
        display: inline-block;
      }
    }
  }

  &-pfki-block {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
}

.mobile {
  .header {
    &-main-block {
      flex-direction: column;
      align-items: center;
      flex: 1;
    }
  }

  .up-btn {
    width: 40px;
    height: 40px;
    &.show {
      opacity: 1;
    }
  }
}

.up-btn {
  position: fixed;
  right: 10px;
  top: 10px;
  width: 133px;
  height: 42px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s;

  &.show {
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }

  &.hide {
    pointer-events: none;
  }
}

.app-wrapper:not(.mobile) .header {
  &-nav-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 42px;

    &_links {
      height: 100%;
      border-bottom: 2px solid #3f5968;
    }

    &_link {
      font-family: "OpenSans";
      color: #3f5968;
      text-decoration: none;
      margin: 10px;
      font-weight: 700;
      size: 20px;
      padding-top: 4px;
      display: inline-block;
    }
  }
}

.app-wrapper.mobile .header {
  min-height: unset;

  &-menu-btn {
    width: 32px;
    height: 32px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 10px;
    left: calc(50% - 16px);
    cursor: pointer;
    transition: top 0.3s;

    &.hide {
      top: -50px;
      pointer-events: none;
    }
  }

  &-close-btn {
    width: 32px;
    height: 32px;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
  }

  &-main-block {
    &_left-side {
      padding-left: unset;
      height: 300px;
    }

    &_logo {
      width: 200px;
      min-width: 150px;
      height: auto;
    }
  }

  &-nav-block {
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    width: 250px;
    background-color: white;
    border-radius: 2px;
    position: absolute;
    right: 0px;
    top: 0px;
    opacity: 0;
    transition: opacity 0.3s;

    &.show {
      opacity: 1;
    }

    &.hide {
      pointer-events: none;
    }

    &_links {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
    }

    &_link {
      font-family: "OpenSans";
      color: #3e5767;
      text-decoration: none;
      margin: 10px;
      font-weight: 700;
      size: 20px;
      padding-top: 4px;
      display: inline-block;
    }
  }

  &-pfki-block {
    img {
      width: 240px;
    }
  }
}
