.postcross {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 220px;
  background: linear-gradient(180deg, #b5cbd8 0%, #fcfefd 100%);

  @media (width < 1920px) {
    padding: 30px 10vw;
  }

  &-title {
    font-family: "Advokat";
    font-size: 80px;
    font-weight: 400;
    line-height: 87.21px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #3b5b70;
  }

  &-content {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;

    &_leftside,
    &_rightside {
      width: 50%;
    }
  }

  &-text {
    position: relative;
    font-family: "OpenSans";
    font-size: 24px;
    font-weight: 300;
    line-height: 26.16px;
    letter-spacing: 0.05em;
    text-align: left;

    &::after {
      content: "";
      width: 100%;
      height: 3px;
      position: absolute;
      background: #b7cdda;
      left: 0;
      bottom: -20px;
    }
  }

  &-posttext {
    font-family: "OpenSans";
    font-size: 32px;
    font-weight: 600;
    line-height: 34.88px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #3b5b70;
  }

  &-postblock {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 50px;
    width: 100%;
    justify-content: space-between;
  }
}

.mobile {
  .postcross {
    padding: 30px 5vw;

    &-title {
      font-size: 40px;
      line-height: 43.6px;
    }

    &-text {
      font-size: 15px;
      line-height: 16.35px;
    }

    &-content {
      flex-direction: column;
      align-items: center;
      gap: 20px;

      &_leftside,
      &_rightside {
        width: 100%;
      }
    }

    &-posttext {
      font-size: 16px;
      line-height: 17.44px;
    }

    &-postblock {
      align-items: start;
    }
  }
}
