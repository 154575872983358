.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 220px;
  background-color: #52756f;

  color: white;
  font-family: "OpenSans";

  @media (width < 1920px) {
    padding: 30px 10vw;
  }

  &-title {
    font-family: "Advokat";
    font-size: 80px;
    font-weight: 400;
    line-height: 87.21px;
    letter-spacing: 0.05em;
    text-align: left;

    color: #b9ddd9;
  }

  &-content {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  &-socials {
    display: flex;
    flex-direction: row;
    align-items: center;

    &_text {
      font-family: "Open Sans";
      font-size: 20px;
      font-weight: 700;
      line-height: 21.8px;
      letter-spacing: 0.05em;
      text-align: left;
    }

    &_links {
      margin-left: 20px;
    }

    &_link {
      margin-right: 10px;
    }
  }

  &-map-block {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
  }

  &-map_left,
  &-map_right {
    p {
      font-family: "OpenSans";
      font-size: 20px;
      font-weight: 400;
      line-height: 21.8px;
      letter-spacing: 0.05em;
      text-align: left;
    }

    img {
      margin-top: 10px;
    }
  }
}

.mobile {
  .footer {
    padding: 30px 5vw;

    &-title {
      font-size: 36px;
      line-height: 39.24px;
    }

    &-content {
      align-items: left;
    }

    &-socials {
      flex-direction: column;
      align-items: flex-start;

      &_text {
        font-size: 20px;
      }

      &_links {
        margin-left: 0;
        margin-top: 10px;
      }

      &_link {
        img {
          width: 32px;
          height: 32px;
        }
      }
    }

    &-map-block {
      flex-direction: column;
      align-items: center;
      width: 95%;
    }
  }
}
