.events {
  width: 100%;
  padding: 50px 220px;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (width < 1920px) {
    padding: 50px 10vw;
  }

  &-title {
    font-family: "Advokat";
    font-size: 80px;
    font-weight: 400;
    line-height: 87.21px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #b9ddd9;
  }

  &-slider {
    margin-top: 50px;
    flex: 1;
  }

  .slick-arrow {
    &:before {
      color: #ffffff;
      font-size: 40px;
    }

    &.slick-prev {
      left: -50px;
    }
  }
}

.event-card {
  max-width: 350px;
  padding: 10px;
  margin: auto;
}

.event-title {
  font-family: "OpenSans";
  font-size: 24px;
  font-weight: 600;
  line-height: 26.16px;
  letter-spacing: 0.05em;
  text-align: left;
  color: white;
  margin-top: 10px;
}

.event-text {
  font-family: "OpenSans";
  font-size: 24px;
  font-weight: 300;
  line-height: 26.16px;
  letter-spacing: 0.05em;
  text-align: left;
  color: white;
  margin-top: 5px;
}

.event-slider-arrow {
  &::before {
    color: #4d706c;
  }
}

.mobile .events {
  padding: 30px 5vw;

  &-title {
    font-size: 36px;
    line-height: 39.24px;
  }

  &-slider {
    margin: auto;
    margin-top: 20px;
    flex: 1;
    width: 85%;
  }

  .event-title {
    font-size: 15px;
    line-height: 16.35px;
  }

  .event-text {
    font-size: 15px;
    line-height: 16.35px;
  }

  .slick-arrow {
    &.slick-prev {
      left: -40px;
    }
  }
}
.event-img-empty {
  display: inline-block;
  width: 263px;
  height: 263px;
}
