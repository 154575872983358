.program {
  display: flex;
  flex-direction: column;
  padding-left: 220px;
  padding-right: 100px;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  background-position: center;

  @media (width < 1920px) {
    padding-left: 10vw;
    padding-right: 5vw;
  }

  &-content {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    gap: 40px;
  }

  &-text-block {
    width: 60%;
    font-family: "OpenSans";
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-align: left;
    color: white;
  }

  &-title {
    font-family: "Advokat";
    font-size: 80px;
    font-weight: 400;
    line-height: 87.21px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #b9ddd9;
  }

  &-readmore {
    margin-top: 15px;
    text-align: right;
    font-family: "OpenSans";
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #c3c3c3;
    cursor: pointer;
  }

  &-pdf-block {
    width: 40%;
    cursor: pointer;
  }
}

.mobile {
  .program {
    padding: 30px 5vw;

    &-content {
      flex-direction: column-reverse;
      align-items: center;
      width: 100%;
    }

    &-text-block {
      width: 100%;
      font-size: 15px;
    }

    &-title {
      font-size: 40px;
      line-height: 43.6px;
    }

    &-pdf-block {
      width: 100%;
      text-align: center;
    }

    &-readmore {
      font-size: 15px;
    }
  }
}
