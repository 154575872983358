@font-face {
  font-family: "OpenSans";
  src: url(../assets/fonts/OpenSans/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Advokat";
  src: url(../assets/fonts/AdvokatModern/AdvokatModern.ttf) format("truetype");
}

.app-wrapper .slick-dots li button:before {
  background-color: #98aec0;
  width: 18px;
  height: 18px;
  content: "";
  opacity: 1;
}

.app-wrapper .slick-dots li.slick-active button:before {
  background-color: #3b5b70;
}
