.about {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 30px 0;
  background: linear-gradient(180deg, #b5cbd8 0%, #fcfefd 100%);

  &-text-block {
    margin: 50px;
    margin-top: 100px;
    width: 1480px;

    @media (width < 1920px) {
      margin-top: 1rem;
    }
  }

  &-title {
    font-family: "Advokat";
    font-size: 80px;
    font-weight: 400;
    line-height: 87px;
    letter-spacing: 5px;
    color: #3b5b70;
    text-align: left;
  }

  &-text {
    margin-top: 30px;
    font-family: "OpenSans";
    font-size: 24px;
    font-weight: 300;
    line-height: 26.16px;
    letter-spacing: 0.05em;
    text-align: left;
    color: black;
    position: relative;
  }

  &-readmore {
    margin-top: 15px;
    text-align: right;
    font-family: "OpenSans";
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.05em;
    cursor: pointer;
    color: #6a6a6a;
  }
}

.mobile .about {
  padding: 30px 5vw;

  &-title {
    font-size: 40px;
    line-height: 43.6px;
  }

  &-text-block {
    margin: unset;
  }

  &-text {
    font-size: 15px;
    font-weight: 600;
    line-height: 16.35px;
    margin-top: 15px;
  }

  &-readmore {
    font-size: 15px;
  }
}
